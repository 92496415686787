// src/components/Login.js
import React, { useState } from "react";
import { useTheme } from "../utils/ThemeContext";
//import loginImg from "../assets/nemt.jpeg";
import { useNavigate } from "react-router-dom";
import Apiservice from "../utils/Apiservice";
// import LottieAnimation from '../utils/LottieAnimation';
// import animationData from '../assets/Animation.json';

const Login: React.FC = () => {
  const { theme } = useTheme();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    if (!email || !password) {
      setError("Please enter both email and password.");
      return;
    }

    try {
      await Apiservice.signInWithEmailAndPassword(email, password);
      navigate("/dashboard"); // Replace with your post-login page route
    } catch (err) {
      setError("Invalid email or password.");
    }
  };
  return (
    <div className='relative w-full h-screen bg-zinc-900/0'>
       {/* <LottieAnimation animationData={animationData} />*/}  

      <div
        className={`min-h-screen flex items-center justify-center bg-secondary-${theme}`}
      >
        
        <div
          className={`w-full max-w-md p-8 space-y-8 rounded-lg shadow-lg bg-primary-${theme}`}
        >
          <h2 className="text-4xl font-bold text-center py-4 ">BRAND.</h2>
          <h2 className="text-center text-3xl font-extrabold text-black">
            Sign in to your account
          </h2>
          <form className="mt-8 space-y-6" onSubmit={handleLogin}>
            <div className="rounded-md shadow-sm -space-y-py">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="username"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className={`appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-${theme} focus:border-primary-${theme} focus:z-10 sm:text-sm`}
                  placeholder="Email address"
                />
              </div>
            </div>
            <div className="rounded-md shadow-sm -space-y-py">
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className={`appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary-${theme} focus:border-primary-${theme} focus:z-10 sm:text-sm`}
                  placeholder="Password"
                />
              </div>
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <div>
              <button
                type="submit"
                className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-${theme} hover:bg-primary-dark-${theme} focus:outline-none focus:ring-4 focus:ring-offset-2 focus:ring-primary-${theme}`}
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
