import React from 'react';
import Header from '../utils/Header';

interface Trip {
  id: number;
  passengerName: string;
  pickupLocation: string;
  dropoffLocation: string;
  pickupTime: string;
  status: 'Scheduled' | 'In Progress' | 'Completed' | 'Cancelled';
}

const trips: Trip[] = [
  { id: 1, passengerName: 'John Doe', pickupLocation: '123 Main St', dropoffLocation: '456 Elm St', pickupTime: '09:00 AM', status: 'Scheduled' },
  { id: 2, passengerName: 'Jane Smith', pickupLocation: '789 Maple Ave', dropoffLocation: '101 Pine St', pickupTime: '10:30 AM', status: 'In Progress' },
  { id: 3, passengerName: 'Sam Wilson', pickupLocation: '321 Oak Dr', dropoffLocation: '654 Cedar Rd', pickupTime: '11:00 AM', status: 'Completed' },
  { id: 4, passengerName: 'Lisa Brown', pickupLocation: '222 Birch Ln', dropoffLocation: '333 Aspen St', pickupTime: '02:00 PM', status: 'Cancelled' },
];

const ViewTrips: React.FC = () => {
  return (
    <div className="min-h-screen flex items-start justify-start bg-gray-100">
    <Header theme={'light'}   />
    <h1 className="text-3xl font-bold">Welcome to the NEMT</h1>
    <main className="pt-10">
      {/* ----Trips-- */}
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Today's Trips</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800 shadow-md rounded-lg">
          <thead>
            <tr>
              <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">Passenger Name</th>
              <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">Pickup Location</th>
              <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">Dropoff Location</th>
              <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">Pickup Time</th>
              <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">Status</th>
            </tr>
          </thead>
          <tbody>
            {trips.map((trip) => (
              <tr key={trip.id} className="border-b dark:border-gray-700">
                <td className="py-2 px-4">{trip.passengerName}</td>
                <td className="py-2 px-4">{trip.pickupLocation}</td>
                <td className="py-2 px-4">{trip.dropoffLocation}</td>
                <td className="py-2 px-4">{trip.pickupTime}</td>
                <td className="py-2 px-4">
                  <span className={`px-2 py-1 rounded-full text-sm ${getStatusClasses(trip.status)}`}>
                    {trip.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    {/* ---End Trips-- */}
    </main>
    </div>
  );
};

const getStatusClasses = (status: string) => {
  switch (status) {
    case 'Scheduled':
      return 'bg-blue-200 text-blue-800';
    case 'In Progress':
      return 'bg-yellow-200 text-yellow-800';
    case 'Completed':
      return 'bg-green-200 text-green-800';
    case 'Cancelled':
      return 'bg-red-200 text-red-800';
    default:
      return '';
  }
};

export default ViewTrips;
