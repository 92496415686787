import React from 'react';
import Header from '../utils/Header';
import BookTrip from './BookTrip';

interface DashBoardProps {
  theme: "light" | "dark";  
}
const Dashboard: React.FC<DashBoardProps> = ({ theme }) => {
  
  return (
    <div className="min-h-screen flex items-start justify-start bg-gray-100">
      <Header theme={'light'}   />
      <h1 className="text-3xl font-bold">Welcome to the NEMT</h1>
      <main className="pt-10">
        <BookTrip theme={theme} />
      </main>
    </div>
  );
};

export default Dashboard;