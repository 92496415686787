import React from 'react';

interface NavItem {
  label: string;
  href: string;
}

interface NavbarProps {
  items: NavItem[];
  theme: 'light' | 'dark';  
}

const Navbar: React.FC<NavbarProps> = ({ items, theme }) => {
  return (
    <nav className={`fixed top-0 left-0 w-full ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'} shadow-md`}>
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <div className="flex space-x-6">
          {items.map((item) => (
            <a 
              key={item.label}
              href={item.href} 
              className="text-lg font-medium hover:text-blue-500"
            >
              {item.label}
            </a>
          ))}
        </div>
        <button        
          className="text-sm px-4 py-2 border rounded-full focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-800"
        >         
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
