import React, { useState } from "react";
//import avatar from "../assets/avatar.png";
import Navbar from "./NavBar";

interface HeaderProps {
  theme: "light" | "dark";  
}
const navItems = [
    { label: 'Book Trip', href: '/dashboard' },
    { label: 'View Trips', href: '/trips' },
    { label: 'Logout', href: '/' }
  ];

const Header: React.FC<HeaderProps> = ({ theme }) => {
  //const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <header
      className={`fixed top-0 left-0 w-full flex items-center justify-between p-4 ${
        theme === "light" ? "bg-gray-900 text-white" : "bg-white text-gray-900"
      } shadow-lg z-50`}
    >
        <h1 className="ml-4 text-xl font-bold"><Navbar items={navItems} theme={theme} /></h1>
     
    </header>
  );
};

export default Header;
