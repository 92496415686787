// src/App.js
import React from 'react';
import Router from './components/Router';
import { ThemeProvider } from './utils/ThemeContext';


const App = () => {  

  return (
    <ThemeProvider>
    <div className="App">
      <Router />
    </div>
  </ThemeProvider>
  );
}

export default App;
