import axios from 'axios';

const serviceEndpoint = "https://us-central1-digitalplanet-de019.cloudfunctions.net/digitalApi/api/v1/";


export default class ApiService {

    static signInWithEmailAndPassword(email:string, password:string) {
        const request = {
            "operation": "loginMember",
            "request": {              
                "userName": email,
                "password": password

            }
        }
        const api = serviceEndpoint+"customLogin"       
        console.log("calling login service " +  api);
        return axios.post(api, request); 
    }
}