// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboad";
import ViewTrips from "./ViewTrips";
import { AuthProvider } from "../utils/AuthProvider";


// import Dashboard from './Dashboard';  // Post-login page

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard theme={"light"} />} />
          <Route path="/trips" element={<ViewTrips />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
